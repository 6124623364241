<template>

	<div class="page-jurisdiction-request">

		<p class="mb-4">
			<router-link to="/" class="btn btn-sm">← Select municipality</router-link>
		</p>

		<div class="row justify-content-center">
			<div class="col-11 col-md-9 col-lg-7">
				<h2 class="text-center mb-4">No HeyGov 😢</h2>

				<p>Looks like your municipality doesn't use HeyGov yet..</p>

				<p>If you think HeyGov will be a useful tool, please fill the form. We'll show your local government there's interest for this app.</p>

				<form @submit.prevent="jurisdictionRequest">
					<div class="form-group">
						<label for="req-name">Your name</label>
						<input type="text" class="form-control" v-model="jurisdiction.name" id="req-name">
					</div>
					<div class="form-group">
						<label for="req-email">Your email</label>
						<input type="email" class="form-control" id="req-email" required v-model="jurisdiction.email" aria-describedby="emailHelp">
						<small id="emailHelp" class="form-text text-muted">Will not be shared with anyone, we'll just notify you when HeyGov becomes available.</small>
					</div>
					<div class="form-group">
						<label for="req-gov">Your municipality</label>
						<input type="text" class="form-control" required v-model="jurisdiction.jurisdiction" id="req-gov">
					</div>
					<div class="form-group">
						<label for="req-gov-email">Local gov email</label>
						<input type="email" class="form-control" id="req-gov-email" v-model="jurisdiction.jurisdiction_email">
					</div>
					<p class="text-center"><button type="submit" class="btn btn-primary">Continue</button></p>
				</form>
			</div>
		</div>

	</div>

</template>

<script>
import { mapState } from 'vuex'
import heyGovApi from '@/api.js'
import Vue from 'vue'


export default {
	name: 'JurisdictionRequest',
	data() {
		return {
			jurisdiction: {
				name: '',
				email: '',
				jurisdiction: '',
				jurisdiction_email: '',
			}
		}
	},
	computed: {
		...mapState([ 'j' ]),
	},
	methods: {
		jurisdictionRequest() {
			heyGovApi.post(`/jurisdictions/request`, this.jurisdiction).then(() => {
				Vue.toasted.show(`Thanks, we'll let you know when your municipality is added to HeyGov!`, { type: 'success' })
			}, error => {
				Vue.toasted.show(error, { type: 'error' })
			})
		}
	},
}
</script>
