<template>

	<div class="home text-center py-6">

		<h1>404 Not found</h1>
		<p class="lead">The page you're looking for is not here..</p>

	</div>

</template>

<script>
export default {
	name: 'PageNotFound',
}
</script>
