<template>
	<div class="page-about">
		<h3 class="mb-4">About HeyGov</h3>

		<p>This app is made by <a href="https://heygov.com" target="_blank">HeyGov.com</a></p>
		<p>Support: support@heygov.com</p>

		<p v-on:dblclick="$router.push('/dev/debug')">Version: {{ version }}</p>

		<p>Legal</p>
		<ul class="mb-5">
			<li><a href="https://www.heygov.com/privacy-policy" target="_blank">Privacy policy</a></li>
			<li><a href="https://www.heygov.com/terms-and-conditions" target="_blank">Terms and Conditions</a></li>
		</ul>

		<p class="text-center text-muted">Copyright &copy; 2024 HeyGov</p>
	</div>
</template>

<script>
import { version } from '@/../package.json'

export default {
	name: 'About',
	data() {
		return {
			version,
		}
	},
}
</script>
